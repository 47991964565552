<template>
  <div class="wrapper">
    <div class="title">
      <h2>2021中国高端酒展览会(春季)</h2>
      <h2>商务合作通道</h2>
      <p>时间：2021年3月5-7日</p>
      <p>地点：广州·保利世贸博览馆</p>
    </div>

    <van-form @submit="onSubmit">
      <h3 class="dot">您的公司名称</h3>
      <van-field
        v-model="companyName"
        name="companyName"
        placeholder=""
        border
        :rules="[{ required: true, message: '请填写公司名称' }]"
      />

      <h3 class="dot">您的公司所在城市</h3>
      <van-field
        v-model="city"
        name="city"
        placeholder=""
        border
        :rules="[{ required: true, message: '请填写公司所在城市' }]"
      />

      <h3 class="dot">您的合作意向</h3>

      <van-field
        name="intent"
        label=""
        style="border: none"
        :rules="[{ required: true, message: '请填写合作意向' }]"
      >
        <template #input>
          <van-radio-group
            v-model="radio"
            direction="vertical"
            @change="handleRaidoChange"
          >
            <van-radio name="购买展位">购买展位</van-radio>
            <van-radio name="论坛赞助">论坛赞助</van-radio>
            <van-radio name="3">其它</van-radio>
            <van-field v-show="radioSelect ==='3'" name="x"  v-model="x" placeholder="其它说明" />
          </van-radio-group>

        </template>
      </van-field>

      <h3 class="dot">您的姓名-职务</h3>
      <van-field
        v-model="name"
        name="name"
        placeholder=""
        :rules="[{ required: true, message: '请填写姓名-职务' }]"
      />

      <h3 class="dot">您的联系方式</h3>
      <van-field
        v-model.trim="tel"
        name="tel"
        type="tel"
        placeholder=""
        :rules="[
          { required: true, message: '请填写手机号!' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！请重新输入!'}
         ]"
      />

      <h3>推荐人</h3>
      <van-field
        v-model="reference"
        name="reference"
        placeholder=""
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>

</template>

<script>

import {insert} from "@/api"
import {Toast} from "vant";
export default {
  name: "GZ",
  data() {
    return {
      name: '',
      password: '',
      radio:"",
      companyName:"",
      radioSelect:"",
      intent:"", //意向
      detail:"",//其它说明
      tel:"", // 电话
      x:"",
      city:"", // 城市
      reference:"", // 推荐人
    };
  },
  methods: {
    onSubmit(values) {
      debugger
      values.type = "1";
      if (values.x.trim()!==""){
       values.intent = values.x;
      }

      insert(values).then(res=>{
        if (res.code===200){
          Toast.success(res.msg);
          this.$router.replace("/success")
        }else{
          Toast.success(res.msg);
        }
      })
    },
    handleRaidoChange(name){
      this.radioSelect = name;
    }
  },
}
</script>

<style scoped>

.wrapper{
  width: 90vw;
  margin: 0 auto;
}


.van-cell {
  border: 1px solid #d4d4d4;
  border-radius: 7px;
  margin-bottom: 20px;
}

.van-cell::after {
  border-bottom: none;

}
.title{
  text-align: center;
}

.van-radio {
  margin-bottom: 15px;
}

.dot {
  position: relative;
}

.dot:after{
  content: "*";
  color:red;
  top: 4px;
  left: -10px;
  position: absolute;
}
</style>
